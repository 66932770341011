<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="650px"
      class="mx-auto my-12"
    >
      <v-card>
        <v-card-title>
          SMS
          <v-spacer></v-spacer>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="sendSms()"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="form.recipient"
                  :items="recipients"
                  item-text="name"
                  item-value="value"
                  label="Recipient"
                  :loading="isRecipientsLoading"
                  :rules="[v => !!v || 'Recipient is required']"
                  outlined
                  required
                ></v-select>
                <small
                  v-show="form.errors.has('recipient')"
                  class="validation-error"
                >{{
                  form.errors.get('recipient')
                }}</small>
              </v-col>
              <v-expand-transition>
                <v-col cols="12">
                  <v-autocomplete
                    v-show="showSpecificUsersSelect"
                    v-model="form.specific_recipients"
                    :items="users"
                    :loading="isUsersLoading"
                    :search-input.sync="users_search"
                    :filter="filterObject"
                    clearable
                    hide-details
                    item-text="name"
                    item-value="phone"
                    cache-items
                    hide-no-data
                    label="Select customers"
                    placeholder="Search customer by name or phone"
                    class="mb-6"
                    outlined
                    multiple
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Search customer name
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remove(data.item)"
                      >
                        <v-avatar
                          left
                          class="primary font-weight-light white--text"
                        >
                          {{ data.item.name.charAt(0) }}
                        </v-avatar>
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-avatar class="primary font-weight-light white--text">
                        {{ data.item.name.charAt(0) }}
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-text="data.item.name"></v-list-item-title>
                        <v-list-item-subtitle v-text="data.item.phone"></v-list-item-subtitle>
                        <br />
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                  <small
                    v-show="form.errors.has('meter_id')"
                    class="validation-error"
                  >{{
                    form.errors.get('meter_id')
                  }}</small>
                </v-col>
              </v-expand-transition>
              <v-expand-transition>
                <v-col cols="12">
                  <v-textarea
                    v-show="showSpecificPhoneNumberInput"
                    v-model="form.specific_phone_numbers"
                    label="Phone numbers"
                    :rules="showSpecificPhoneNumberInput ? [v => !!v || 'Phone number is required'] : []"
                    rows="1"
                    row-height="5"
                    hint="Enter phone numbers separated by comma"
                    placeholder="07********, 07********"
                    auto-grow
                    outlined
                  ></v-textarea>
                  <small
                    v-show="form.errors.has('specific_phone_numbers')"
                    class="validation-error"
                  >{{
                    form.errors.get('specific_phone_numbers')
                  }}</small>
                </v-col>
              </v-expand-transition>
              <v-col cols="12">
                <v-textarea
                  v-model="form.message"
                  clearable
                  label="Type your message here"
                  outlined
                  auto-grow
                  :rules="message_rules"
                  required
                ></v-textarea>
                <small
                  v-show="form.errors.has('message')"
                  class="validation-error"
                >{{
                  form.errors.get('message')
                }}</small>
              </v-col>
            </v-row>
            <v-row
              class="mt-1 mb-2"
              justify="center"
            >
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    View Sms templates
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          Short templates
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-col>
                            <div class="grey--text">
                              Use this templates to personalize messages. Here are some of available templates that you can use.
                              Click on template name to quickly append it to message
                            </div>
                            <v-row class="mt-2">
                              <v-col cols="12 pd-0">
                                <div class="grey--text Caption">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <a
                                        href="#"
                                        class="no-underline"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click.prevent="appendToMessage('{first-name}')"
                                      >{first-name}</a>
                                    </template>
                                    <span>Click to append to message</span>
                                  </v-tooltip>
                                  : Gets the specific customer first name
                                </div>
                              </v-col>
                              <v-col cols="12 pd-0 pt-0">
                                <div class="grey--text Caption">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <a
                                        href="#"
                                        class="no-underline"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click.prevent="appendToMessage('{full-name}')"
                                      >{full-name}</a>
                                    </template>
                                    <span>Click to append to message</span>
                                  </v-tooltip>
                                  : Gets the specific customer full name
                                </div>
                              </v-col>
                              <v-col cols="12 pt-0">
                                <div class="grey--text Caption">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <a
                                        href="#"
                                        class="no-underline"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click.prevent="appendToMessage('{account-number}')"
                                      >{account-number}</a>
                                    </template>
                                    <span>Click to append to message</span>
                                  </v-tooltip>
                                  : Gets the specific customer account number
                                </div>
                              </v-col>
                              <v-col cols="12 pt-0">
                                <div class="grey--text Caption">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <a
                                        href="#"
                                        class="no-underline"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click.prevent="appendToMessage('{meter-number}')"
                                      >{meter-number}</a>
                                    </template>
                                    <span>Click to append to message</span>
                                  </v-tooltip>
                                  : Gets the specific customer meter number
                                </div>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          Long templates
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div class="grey--text">
                            Use this templates to send common repeated messages. Click on template message to use it.
                          </div>
                          <v-col cols="12">
                            <v-form
                              ref="messageTemplateForm"
                              method="post"
                              action="/"
                              lazy-validation
                              @submit.prevent="saveMessageTemplate()"
                            >
                              <v-textarea
                                v-model="smsTemplateForm.message"
                                clearable
                                label="Add new message template here"
                                rows="3"
                                outlined
                                auto-grow
                                class="mb-0"
                                @keydown="showSaveMessageTemplateButton = true"
                              ></v-textarea>
                              <small
                                v-show="smsTemplateForm.errors.has('message')"
                                class="validation-error"
                              >{{
                                smsTemplateForm.errors.get('message')
                              }}</small>
                              <v-btn
                                v-show="showSaveMessageTemplateButton"
                                color="primary"
                                class="float-right"
                                type="submit"
                                :loading="smsTemplateForm.busy"
                                small
                              >
                                Save Template
                              </v-btn><br>
                            </v-form>
                          </v-col>
                          <v-col
                            cols="12"
                            class="mt-4"
                          >
                            <p v-if="smsTemplates.length ===0">
                              There are no any template messages added
                            </p>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-row
                                  v-for="(template, index) in smsTemplates"
                                  :key="index"
                                >
                                  <v-col cols="11">
                                    <a
                                      href="#"
                                      class="no-underline"
                                      v-bind="attrs"
                                      v-on="on"
                                      @click.prevent="setMessageTemplate(template.message)"
                                    >
                                      {{ template.message }}
                                    </a><br>
                                  </v-col>
                                  <v-col cols="1">
                                    <v-tooltip
                                      bottom
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          ma-2
                                          v-bind="attrs"
                                          text
                                          icon
                                          :loading="index in messageTemplatesDeleting"
                                          v-on="on"
                                          @click="deleteMessageTemplate(template.id)"
                                        >
                                          <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                          >
                                            {{ icons.mdiDelete }}
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Delete</span>
                                    </v-tooltip>
                                  </v-col>
                                </v-row>
                              </template>
                              <span>Click to use</span>
                            </v-tooltip>
                          </v-col>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              Send
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiDelete } from '@mdi/js'
import { mapGetters } from 'vuex'
import Form from 'vform'

export default {
  props: {
    showDialog: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: new Form({
        recipient: '',
        specific_recipients: '',
        specific_phone_numbers: '',
        message: '',
        station_id: '',
      }),
      smsTemplateForm: new Form({
        message: '',
      }),
      isRecipientsLoading: false,
      isSmsTemplatesLoading: false,
      showSpecificUsersSelect: false,
      showSpecificPhoneNumberInput: false,
      showSaveMessageTemplateButton: false,
      isUsersLoading: false,
      users: [],
      users_search: null,
      messageTemplatesDeleting: [],
      message_rules: [
        v => !!v || 'Message is required',
      ],
      recipients: [],
      smsTemplates: [],
      icons: {
        mdiDelete,
      },
    }
  },
  computed: {
    ...mapGetters(['selectedStation']),
  },
  watch: {
    users_search(value) {
      this.getUsers(value)
    },
    'form.recipient': function () {
      if (this.form.recipient === 'specific-customer') {
        this.showSpecificUsersSelect = true
      } else {
        this.showSpecificUsersSelect = false
      }
      if (this.form.recipient === 'specific-phone-numbers') {
        this.showSpecificPhoneNumberInput = true
      } else {
        this.showSpecificPhoneNumberInput = false
      }
    },
    selectedStation() {
      this.form.station_id = this.selectedStation
    },
    showDialog(value) {
      if (value && this.users.length === 0) {
        this.getRecipients()
      }
    },
  },
  mounted() {
    this.form.station_id = this.selectedStation
    this.getSmsTemplates()
  },
  methods: {
    getRecipients() {
      this.isRecipientsLoading = true
      axios
        .get('meter-stations')
        .then(response => {
          this.recipients.push({ name: 'All customers', value: 'all' })
          response.data.forEach(station => {
            this.recipients.push({ name: `${station.name} customers`, value: station.id })
          })
          this.recipients.push({ name: 'Specific customers', value: 'specific-customer' })
          this.recipients.push({ name: 'Specific phone numbers', value: 'specific-phone-numbers' })
          this.isRecipientsLoading = false
        })
        .catch(error => {
          this.isRecipientsLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    sendSms() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .post('sms')
          .then(() => {
            this.$notification.success('Message sent successfully')
            this.$emit('sent')
            this.$refs.form.reset()
          })
          .catch(error => {
            this.$notification.error(error.response.data.message)
          })
      }
    },
    getUsers(value) {
      this.isUsersLoading = true
      axios
        .get(`users?searchByNameAndPhone=${value}`)
        .then(response => {
          this.users = response.data.data
          this.isUsersLoading = false
        })
        .catch(error => {
          this.isUsersLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    getSmsTemplates() {
      this.isSmsTemplatesLoading = true
      axios
        .get('sms-templates')
        .then(response => {
          this.smsTemplates = response.data
          this.isSmsTemplatesLoading = false
        })
        .catch(error => {
          this.isSmsTemplatesLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    saveMessageTemplate() {
      this.smsTemplateForm
        .post('sms-templates')
        .then(() => {
          this.$notification.success('Message template saved successfully')
          this.showSaveMessageTemplateButton = false
          this.$refs.messageTemplateForm.reset()
          this.getSmsTemplates()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
    },
    deleteMessageTemplate(id) {
      this.messageTemplatesDeleting.push(id)
      axios
        .delete(`sms-templates/${id}`)
        .then(() => {
          this.messageTemplatesDeleting.pop(id)
          this.$notification.success('Message template deleted successfully')
          this.getSmsTemplates()
        })
        .catch(error => {
          this.messageTemplatesDeleting.pop(id)
          this.$notification.error(error.response.data.message)
        })
    },
    filterObject(item, queryText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        || item.phone.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
    remove(item) {
      const index = this.form.specific_recipients.indexOf(item.phone)
      if (index >= 0) this.form.specific_recipients.splice(index, 1)
    },
    appendToMessage(text) {
      if (this.form.message == null) {
        this.form.message = text
      } else {
        this.form.message += text
      }
    },
    setMessageTemplate(message) {
      this.form.message = message
    },
    closeDialog() {
      this.$emit('close')
      this.$refs.form.reset()
    },
  },
}
</script>

<style lang="scss">
.v-menu__content:not(.list-style) .v-list-item {
  min-height: 48px !important;
  height: unset !important;
}
</style>
